@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');
div, p, a, li{ font-family: 'Open Sans', sans-serif; font-size: 1rem; line-height: 1.75rem; font-weight: 300; color: #444444;}
h1,h2,h3,h4{font-family: 'Work Sans', sans-serif;font-size: 2rem; font-weight: 400;}
h1,h2{ font-size: 2.5rem; color: #ffffff;}
h3{padding-bottom: .5em}
.animate-bottom{position:relative;animation:animatebottom 0.8s}@keyframes animatebottom{from{bottom:-40px;opacity:0} to{bottom:0;opacity:1};}
.bg-image-home{background-size:cover; background-position: top center; background-repeat: no-repeat; background-image: url('../public/bg01.jpg');}
.bg-image-map{background-size:cover; background-position: top center; background-repeat: no-repeat; background-image: url('../public/bg-map.jpg');}
.bg-gradient-danube{background:linear-gradient(90deg,rgba(87, 136, 189, 0.75) 5%,rgba(0,0,0,0) 100%); color: white;}
.bg-gradient-sunglow{background-image: linear-gradient(90deg, rgba(254, 204, 56, 0.75) 25%, rgba(0, 0, 0, 0) 100% ); color: #444444;}

.bg-trans-black{background-color: rgba(0, 0, 0, .75); color: #ffffff;}
.bg-crusta-white{background:linear-gradient(180deg,rgba(247, 149, 101, 1) 0%,rgba(247, 149, 101, 1) 30%,rgba(0,0,0,0) 30%,rgba(0,0,0,0) 100%);}

.bg-crusta{ background-color: #F38C60!important; color: white!important;}
.bg-jordyblue{ background-color: #6FA4D5; color: white;}
.border-jordyblue{border: #6FA4D5 solid 1.25rem}
.border-crusta{border: #F38C60 solid 1.25rem}
.bg-white{color: #444444;}

.navbar-toggler{border: 0;}
.navbar-toggler:focus{box-shadow: none;}
.navbar-toggler-icon { background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='rgba(255, 255, 255, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>");}
.map{width: 100%;height:100%;position: relative;padding:2em 0}

.w-90{width: 90%;}
.mw-90{max-width: 98%;}
.menu-links a{ color: #ffffff; text-decoration: none; font-size: .9rem ; margin: .35em;font-family: 'Work Sans', sans-serif;}
.small, .small a{font-size: .68rem;}
.menu-links{animation: animatebottom .8s;}
.pt-gen{padding-top: 5rem}

@media only screen and (min-width: 576px) {
div, p, a, li{ font-size: 1.375rem; line-height: 2.25rem; }
.small, .small a{font-size: .75rem;}
h1,h2{ font-size: 3rem; }
}

@media only screen and (min-width: 768px) {
.navbar-nav{display: none}
}

@media only screen and (min-width: 992px) {
.mw-90{max-width: 90%;}
h1{font-size: 3rem;}
}
